import React, { useState, useEffect } from 'react';
import { Card, Table, Tag, Tooltip, Button, Divider, Spin } from 'antd';
import { EyeOutlined, PlusOutlined, UserOutlined } from '@ant-design/icons';
import AppLayout from '../../components/layout';
import Register from './addClients';
import ClientView from './ClientView';
import AvatarStatus from '../../components/shared-components/AvatarStatus';
import { connect } from 'react-redux';
import { ClientData } from '../../actions/clientAction';
import { navigate } from 'gatsby';
import { Routes } from '../../utils/route';
import { usePosition } from '../../utils/usePosition';
import DataTable from '../../components/data-display/table/DataTable';
import API from '../../api';
import {TextCapitalize, usaFormatNumber } from '../../utils/helper'; 
const { getPrimaryUser } = API;
const Station = (props) => {
  const defaultSettings = {
    enableHighAccuracy: true,
    timeout: Infinity,
    maximumAge: 0,
  };
  const { latitude, longitude } = usePosition(false, defaultSettings);
  const [state, setState] = useState({
    users: props.clientData,
    userProfileVisible: false,
    selectedUser: null,
  });
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const callback = (val) => {
      if (val === true) {
        props.ClientData();
        setLoading(false);
      }
    };
    Routes('superAdmin', callback);
  }, []);

  useEffect(
    () => {
      setState({ ...state, users: props.clientData });
    },
    [props.clientData]
  );

  useEffect(
    () => {
      const _users = [...users];
      _users &&
        _users.map((item) => {
          if (item.client_id === props.clientId) {
            item.status = props.client_status;
          }
        });
      setState({ ...state, users: _users });
    },
    [props.clientId, props.client_status]
  );

  const showUserProfile = (userInfo) => {
    setState({
      ...state,
      userProfileVisible: true,
      selectedUser: { ...userInfo, userDataLoader: true },
    });
    (async function() {
      const user = await getPrimaryUser(userInfo.client_id);
      if (user) {
        setState({
          ...state,
          userProfileVisible: true,

          selectedUser: {
            ...userInfo,
            user: user.data.data,
            userDataLoader: false,
          },
        });
      }
    })();
  };

  const closeUserProfile = () => {
    setState({
      ...state,
      userProfileVisible: false,
      selectedUser: null,
    });
  };
  const { users, userProfileVisible, selectedUser } = state;
  const tableColumns = [
    {
      name: 'CLIENT ID',
      label: 'CLIENT ID',
      options: { filter: true, sort: true },
    },
    {
      name: 'COMPANY NAME',
      label: 'COMPANY NAME',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          const val = value.split('&');
          return (
            <div className="d-flex text-uppercase justify-content-flex-start" >
              <AvatarStatus
                src={
                  val[2] ? val[2] : <UserOutlined style={{ fontSize: 20 }} />
                }
                name={val[0]}
                subTitle={val[1]}
              />
            </div>
          );
        },
      },
    },
    { name: 'PHONE', label: 'PHONE', options: { filter: true, sort: true,customBodyRender: value =>usaFormatNumber(value) } },
    {
      name: 'CONTACT NAME',
      label: 'CONTACT NAME',
      options: { filter: true, sort: true,customBodyRender: value =>TextCapitalize(value) },
    },
    { name: 'STATUS', label: 'STATUS', options: { filter: true, sort: true,customBodyRender:value=><Tag
      className="text-capitalize"
      color={value === 0 ? 'red' : 'cyan'}
    >
      {value === 0 ? 'Inactive' : 'Active'}
    </Tag> } },
    {
      name: 'ADDRESS',
      label: 'ADDRESS',
      options: { filter: true, sort: true,customBodyRender: value =>TextCapitalize(value) },
    },
    {
      name: 'ACTION',
      label: 'ACTION',
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({
          style: {
            whiteSpace: 'nowrap',
            position: 'sticky',
            right: '0',
            background: '#f0f2f5',
            zIndex: 100,
            textAlign: 'center',
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: 'nowrap',
            position: 'sticky',
            right: 0,
            color: 'white',
            // background: "#1890ffd1",
            zIndex: 101,
          },
        }),
      },
    },
  ];
  const tableData = [];
  users &&
    users.map((item) => {
      tableData.push([
        item.client_id,
        `${item.company_name}&${item.email}&${item.img??null}`,
        item.phone,
        item.name,item.status,
        `${item.street_address} ${item.city} ${item.state}`,
        <div className="text-right">
          <Tooltip title="View">
            <Button
              type="primary"
              className="mr-2"
              icon={<EyeOutlined />}
              onClick={() => {
                showUserProfile(item);
              }}
              size="small"
            />
          </Tooltip>
          {/* <Tooltip title="Delete">
							<Button danger icon={<DeleteOutlined />} onClick={()=> {deleteUser(elm.client_id)}} size="small"/>
						</Tooltip> */}
        </div>,
      ]);
    });
  const columns = [
    {
      title: 'CLIENT ID',
      dataIndex: 'client_id',
      sorter: {
        compare: (a, b) => {
          a = a.client_id;
          b = b.client_id;
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
      align: 'center',
    },
    {
      title: 'COMPANY NAME',
      dataIndex: 'company_name',
      render: (_, record) => (
        <div className="d-flex">
          <AvatarStatus
            src={
              record.img ? (
                record.img
              ) : (
                <UserOutlined style={{ fontSize: 20 }} />
              )
            }
            name={record.company_name}
            subTitle={record.email}
          />
        </div>
      ),
      sorter: {
        compare: (a, b) => {
          a = a.company_name.toLowerCase();
          b = b.company_name.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: 'PHONE',
      dataIndex: 'phone',
      sorter: {
        compare: (a, b) => a.phone.length - b.phone.length,
      },
    },
    {
      title: 'CONTACT NAME',
      dataIndex: 'name',
      sorter: {
        compare: (a, b) => {
          a = a.name.toLowerCase();
          b = b.name.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      render: (status) => (
        <Tag className="text-capitalize" color={status === 0 ? 'red' : 'cyan'}>
          {status === 0 ? 'Inactive' : 'Active'}
        </Tag>
      ),
      sorter: {
        compare: (a, b) => {
          a = a.status;
          b = b.status;
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: 'ADDRESS',
      dataIndex: 'street_address',
      sorter: {
        compare: (a, b) => {
          a = a.street_address;
          b = b.street_address;
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
      render: (_, record) => (
        <div className="">
          <div>{record.street_address}</div>
          <div className="text-light">
            {record.city}, {record.state}
          </div>
        </div>
      ),
    },

    {
      title: '',
      dataIndex: 'actions',
      render: (_, elm) => (
        <div className="text-right">
          <Tooltip title="View">
            <Button
              type="primary"
              className="mr-2"
              icon={<EyeOutlined />}
              onClick={() => {
                showUserProfile(elm);
              }}
              size="small"
            />
          </Tooltip>
          {/* <Tooltip title="Delete">
							<Button danger icon={<DeleteOutlined />} onClick={()=> {deleteUser(elm.client_id)}} size="small"/>
						</Tooltip> */}
        </div>
      ),
    },
  ];
  if (!loading) {
    return (
      <AppLayout>
        <div
          style={{
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'space-between',
          }}
        >
          <h3 style={{ marginBottom: 0 }}>CLIENT LIST</h3>
          <div>
            <Register
              Button={
                <span>
                  <PlusOutlined /> CLIENT
                </span>
              }
              title="Add Client"
            />
          </div>
        </div>
        <Divider />

        <Card bodyStyle={{ padding: '0px' }}>
          {/* <Table
          loading={loading}
          columns={columns}
          dataSource={users}
          rowKey="id"
        /> */}
          <DataTable
            title={<h3 style={{ marginBottom: 0 }}>CLIENT LIST</h3>}
            header={tableColumns}
            data={tableData}
            rowKey="id"
          />
          <ClientView
            data={selectedUser}
            visible={userProfileVisible}
            close={() => {
              closeUserProfile();
            }}
            addUserViewTab={(data) => showUserProfile(data)}
          />
        </Card>
      </AppLayout>
    );
  } else {
    return (
      <div className="spinner-main">
        <Spin />
      </div>
    );
  }
};
const mapStateToProps = (state) => ({
  loading: state.client.loading,
  client_status: state.client.client_status,
  clientId: state.client.clientId,
  clientData: state.clientData,
  updateUserData: state.user.updateUserData,
});
export default connect(mapStateToProps, { ClientData })(Station);
